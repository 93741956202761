
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { Mutual } from "@/core/model/Mutual";

export default defineComponent({
  name: "mutual-info",
  components: {
    //Dropdown,
  },
  setup() {
    const store = useStore();
    const mutual = ref<Mutual>({} as Mutual);

    onMounted(() => {
      loadData();
    });

    const loadData = () => {
      store.dispatch(Actions.MUTUAL_GET).then(() => {
        mutual.value = store.state.MutualesModule.mutual;
      });
    };

    return {
      mutual,
    };
  },
});
